
import { api } from "@/api/api";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import { ApiGetShortCurriculumDto } from "@/api/generated/Api";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { getForApprovalStatus } from "@/shared/helpers/curriculumHelpers";
import { formatDate, formatRelative } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { DashboardRouteNames } from "@/shared/enums/RouteNames/dashboardRouteNames.enum";

export default defineComponent({
  name: "PlansForApprovalPage",
  components: {
    BaseTableFiltered,
    BaseLayout,
  },
  setup() {
    const router = useRouter();
    const store = useStore<StoreState>();
    const curriculumForApproval = ref<ApiGetShortCurriculumDto[]>([]);
    const search = ref<string>("");

    const openImplementationPlanPage = (id: number) => {
      router.push(`/studieplaner/details/gjennomforing/${id}`);
    };

    onMounted(async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        const statusApproval = await getForApprovalStatus(store);
        if (!statusApproval?.id) {
          openNotification(store, NotificationItemType.Error, "Finner ikke status 'Til godkjenning' i courseStatuses");
          return;
        }

        const response = await api.curriculum.getCurriculaAsync({ StatusIds: [statusApproval.id] });
        curriculumForApproval.value = response.data;
      });
    });

    return {
      curriculumForApproval,
      headers,
      search,
      formatDate,
      formatRelative,
      openImplementationPlanPage,
      DashboardRouteNames,
    };
  },
});

const headers = [
  { text: "Id", value: "id" },
  { text: "Handlinger", value: "actions" },
  { text: "Navn", value: "name" },
  {
    text: "Avdeling",
    value: "orgName",
  },
  { text: "Hovedområde", value: "mainCourseName" },
  { text: "Kursområde", value: "subCourseName" },
  {
    text: "Status",
    value: "curriculumStatusName",
  },
  {
    text: "Opprettet av",
    value: "insertedByUsername",
  },
  {
    text: "Sendt til godkjenning",
    value: "inserted",
  },
  {
    text: "Opprettet dato",
    value: "updated",
  },
];
