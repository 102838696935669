var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "left": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push({
                name: _vm.DashboardRouteNames.Home
              });
            }
          }
        }, [_vm._v("mdi-arrow-left")]), _vm._v(" Arbeidstimer til godkjenning ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-text-field', {
          staticClass: "mr-5",
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk i arbeidstimer",
            "single-line": "",
            "hide-details": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        })];
      },
      proxy: true
    }])
  }, [[_c('BaseTableFiltered', {
    staticClass: "mt-3",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.workingHoursPendingApproval,
      "search": _vm.search,
      "sort-desc": true,
      "sort-by": "date"
    },
    scopedSlots: _vm._u([{
      key: "item.date",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s(_vm.formatLocalizedDate(value)) + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "displayWorkingHourEntryBtn-".concat(item.id)
                },
                on: {
                  "click": function click($event) {
                    return _vm.displayWorkingHourEntry(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v("mdi-open-in-new")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis registrering")])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "approveWorkingHourEntryBtn-".concat(item.id)
                },
                on: {
                  "click": function click($event) {
                    return _vm.approveWorkingHourEntry(item.id);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v("mdi-file-sign")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Godkjenn timer")])])];
      }
    }], null, true)
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }